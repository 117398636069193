<template>
  <header
    id="main-header"
    class="d-flex flex-column justify-content-center align-items-center"
  >
    <div class="width-limiter">
      <div id="top-bar">
        <div class="container-fluid">
          <div class="row align-items-center">
            <!-- Mobile Menu Toggle Button  [mobile only]-->
            <div class="col-auto d-md-none">
              <div class="menu-btn" data-cy="menu-button" @click="toggleMenu">
                <icon type="bars" width="33px" height="33px" />
              </div>
            </div>
            <!-- /Mobile Menu Toggle Button -->

            <!-- MStore Logo [always visible] -->
            <div class="col-auto">
              <router-link
                :to="{
                  name: 'home',
                  params: { resetFilters: true, react: true },
                }"
                aria-label="MStore"
              >
                <img src="https://res.cloudinary.com/mstore/image/upload/v1633444335/MSTORE-LOGO_xcnbjt.svg" width="100px" height="41px" color="white" alt="MStore" title="MStore" >
              </router-link>
            </div>
            <!-- /MStore Logo -->

            <!-- Tagline [MD and up only]-->
            <div class="col-auto d-none d-md-block">
              <div class="tagline">Protecting People Since 2003</div>
            </div>
            <!-- /Tagline -->

            <!-- Spacer [Mobile only] -->
            <div class="col d-md-none flex-shrink-1"></div>
            <!-- /Spacer -->

            <!-- Search [MD and up only]-->
            <div class="d-none d-md-block col">
              <search />
            </div>
            <!-- /Search -->

            <!-- Mobile Search Button [Mobile only]-->
            <div class="col-auto text-white d-md-none" style="font-size: 2em">
              <router-link :to="{ name: 'search' }">
                <icon type="search" height="33px" width="33px" />
              </router-link>
            </div>
            <!-- /Mobile Search -->

            <!-- Account Link [Always visible]-->
            <div class="col-auto text-white d-none d-md-block" style="font-size: 2em">
              <router-link class="text-white" aria-label="my account" data-cy="account-details-link" :to="{ name: 'account-details' }">
                <icon type="user" height="33px" width="33px" />
              </router-link>
            </div>
            <!-- /Account Link -->

            <!-- Cart-->
            <div class="col-auto">
              <cart />
            </div>
            <!-- /Cart -->
          </div>
        </div>
      </div>
    </div>
    <div
      style="background: #363738; width: 100%"
      class="d-flex flex-column align-items-center"
    >
      <div class="width-limiter">
        <vave-nav />
      </div>
    </div>
    <div style="width: 100%">
      <slither style="background:#feb103;color:black;text-align:center;">
        <div class="slither-item">
          <router-link :to="{ name: 'newsletter' }">
            <icon class="icon" type="tag" height="18px" width="18px" />
            &nbsp;10% off your first order <sup>*1</sup></router-link
          >
        </div>
        <div class="slither-item d-none d-md-block">
          <icon class="icon" type="truckRampBox" height="18px" width="18px" />
          &nbsp;free delivery over £50
        </div>
        <div class="slither-item d-none d-md-block">
          <icon class="icon" type="truckFast" height="18px" width="18px" />
          &nbsp;fast delivery
        </div>
      </slither>
    </div>
    <div style="width: 100%" v-if="$store.state.app.utm && $store.state.app.utm && $store.state.app.utm.utm_source == 'GardeningGloves' && $store.state.app.utm.utm_campaign == 'show_banner'">
      <slither style="background:red;color:white;text-align:center;">
        <div class="slither-item d-block d-md-none">
          <a @click.prevent="redirectToGardeningGlovesLandingPage()" href="#" style="color:white;">Gardening Gloves is now part of Mstore</a>
        </div>
        <div class="slither-item d-none d-md-block">
          Gardening Gloves is now part of Mstore!
          <a @click.prevent="redirectToGardeningGlovesLandingPage()" href="#" style="color:white;">Click here</a> to find out more
        </div>
      </slither>
    </div>
    <div id="mobile-menu" :class="{ show: showingMenu }" style="display:none;">
      <router-link
        :to="{ name: 'account-details' }"
        data-cy="account-details-mobile-link"
        ><span class="icon" style="padding: 0px; color:black;" 
          ><icon type="user" height="40px" width="40px" /> </span
        >My Account</router-link
      >
      <router-link
        :to="{
          name: 'products',
          params: {
            react: true,
          },
        }"
        ><span class="icon" style="padding: 0px"
          ><img
            src="/images/PPE Icons-03.svg"
            style="margin-top: 15px"
            alt="ppe icon"
            class="img-fluid"
            width="60px"
            height="60px"
          /> </span
        >All Products</router-link
      >
      <router-link
        :to="{
          name: 'category',
          params: {
            category: 'Hand Protection',
            react: true,
          },
        }"
        ><span class="icon" style="padding: 0px"
          ><img
            src="/images/PPE Icons-03.svg"
            alt="ppe icon"
            style="margin-top: 15px"
            class="img-fluid"
            width="60px"
            height="60px"
          /> </span
        >Hand Protection</router-link
      >
      <router-link
        :to="{
          name: 'category',
          params: {
            category: 'Respiratory Protection Equipment',
            react: true,
          },
        }"
        ><span class="icon"
          ><img
            src="/images/PPE Icons-01.svg"
            alt="ppe icon"
            style="margin-top: 1px"
            class="img-fluid"
            width="60px"
            height="60px"
          /> </span
        >Face Masks &amp; Respiratory</router-link
      >
      <router-link
        :to="{
          name: 'category',
          params: {
            category: 'Eye Protection',
            react: true,
          },
        }"
        ><span class="icon"
          ><img
            src="/images/PPE Icons-02.svg"
            alt="ppe icon"
            style="margin-top: 15px"
            class="img-fluid"
            width="60px"
            height="60px"
          /> </span
        >Safety Eyewear</router-link
      >
      <router-link
        :to="{
          name: 'category',
          params: {
            category: 'Hearing Protection',
            react: true,
          },
        }"
        ><span class="icon"
          ><img
            src="/images/PPE Icons-04.svg"
            alt="ppe icon"
            style="margin-top: 3px"
            class="img-fluid"
            width="60px"
            height="60px"
          /> </span
        >Hearing Protection</router-link
      >
      <router-link
        :to="{
          name: 'category',
          params: {
            category: 'Head Protection',
            react: true,
          },
        }"
        ><span class="icon"
          ><img
            src="/images/PPE Icons-05.svg"
            alt="ppe icon"
            style="margin-top: 3px"
            class="img-fluid"
            width="60px"
            height="60px"
          /> </span
        >Head Protection</router-link
      >
      <router-link to="/clearance">
        <span class="icon red">
          <img
            src="/images/products/clearance.svg"
            alt="clearance"
            style="margin-top: 3px"
            class="img-fluid"
            width="60px"
            height="60px"
          />
        </span>
        Clearance
      </router-link>
      <div class="close" @click="toggleMenu">
        <icon type="chevronUp" width="33px" height="33px" /> Close
      </div>
    </div>
  </header>
</template>

<script>
import slither from "./slither";

import icon from "../ui/icon";
import cart from "../../components/cart";
import search from "./search.vue";
import vaveNav from "./nav.vue";

export default {
  components: {
    slither,

    icon,
    cart,
    search,
    vaveNav,
  },
  methods: {
    toggleMenu() {
      this.showingMenu = !this.showingMenu;
    },
    redirectToGardeningGlovesLandingPage() {
      // Replace utm_campaign so we hide the banner
      let utm = this.$store.state.app.utm
      utm.utm_campaign = 'gardening_gloves_banner_click'
      this.$store.commit('app/setUtm', utm)

      this.$router.push('/gardening-gloves');
    },
  },
  data() {
    return {
      showingMenu: false,
    };
  },
  watch: {
    $route() {
      this.showingMenu = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.width-limiter {
  width: calc(100% - 20px);
  max-width: 1600px;
}
  .slither-item{
    .icon {
      float:left;
      margin-right: 0px;
      position:relative;
      top: 2px;
    }
    a {
      color:black;
      text-decoration:underline;
    }
    font-size: 0.9em;
  }
#main-header {
  background: #202122;

  #top-bar {
    height: 90px;
    display: flex;
    align-items: center;
    .tagline {
      color: white;
      font-size: 0.8em;
      font-weight: normal;
    }
  }
}
.menu-btn {
  color: white;
  font-size: 2.2em;
  margin-right: 10px;
  margin-top: -5px;
}

#mobile-menu {
  display: block!important;
  position: absolute;
  top: 80px;
  background: black;
  color: white;
  left: 0;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  // box-shadow: 0px 100vh 0px 100vh rgba(0, 0, 0, 0.7);
  z-index: 999;
  pointer-events: none;
  transition: all 550ms cubic-bezier(0.175, 0.885, 0.32, 1.2);
  transform: translateX(-100vw);
  opacity: 0;
  height: 500vh;

  a {
    background-color: #323232;
    display: block;
    color: white;
    text-decoration: none !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.5em;
    margin-top: 15px;

    .icon {
      margin-right: 20px;
      width: 80px;
      height: 80px;
      display: inline-block;
      background-color: #feb103;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3px;
      &.red {
        background-color: #f4443f;
      }
    }
  }

  &.show {
    opacity: 1;
    pointer-events: unset;
    transform: translateX(0vw);

    // a {
    //   .icon {

    //   }
    // }
  }

  .close {
    margin-top: 20px;
    font-size: 1.5em;
    background-color: #323232;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 80px;
    i {
      padding-right: 15px;
      margin-top: 12px;
      font-size: 1.3em;
    }
  }
}
</style>